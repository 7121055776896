import React, { useState } from "react";
import { DatePickerComponent, InputField, PercentageInputBox, PriceInputBox, SelectBox, TimeDuration } from "../component/Common";
import { addRemoveType, cardType, countArray, daysArray, emiType, loanTypeList, monthsArray, paymentStatusForMortgage, paymentStatusForProperty, propertyTypes, yearsArray, yesNoArray } from "../constants/form_constatnts";

const EmiDetails = () => {
    const [emiData, setemiData] = useState({
        //emi type
        emiType: '',
        //card details
        cardDetails: [
            {
                cardHolderName: '',
                cardType: '',
                bankCompanyName: '',
                cardExpiryDate: '',
                creditBalance: '',
                creditLimit: '',
            }
        ],
        //loan details
        loanDetails: [
            {
                loanType: '',
                otherLoan: '',
                loanProviderName: '',
                loanAmount: '',
                loanBalance: '',
                propertyType: '',
            }
        ],
        //mortgage property details
        currentMortgageStatus: '',
        currentMortgage: [
            {
                propertyType: '',
                mortgageLenderName: '',
                monthlyPayment: '',
                purchaseDate: '',
                originalAmount: '',
                mortgageDuration:
                {
                    years: '',
                    months: '',
                    days: '',
                },
                interestRate: '',
                amountLeft: '',
                propertyCurrentValue: '',
                mortgagePaymenttype: '',
                arrearsCount: '',
                PropertyAddress: '',
            },
        ],

        //other property status
        otherPropertyStatus: '',
        otherProperties: [
            {
                propertyType: '',
                lenderName: '',
                propertyCurrentValue: '',
                purchaseDate: '',
                purchasePrice: '',
                outstandingBalance: '',
                propertyPaymenttype: '',
            }
        ],
        //main account details
        mainAccountName: '',
        AccountOpenDate: '',
        bankSortCode: '',
        accountNumber: '',
        bankAddress: '',
        newPaymentStatus: '',
    })

    //handle select box changes
    const handleOnChange = (value, name) => {
        setemiData((prevData) => ({ ...prevData, [name]: value }));
    };

    //handle type selection of loan and card
    const handleTypeSelect = (index, value, name, type) => {
        const newCard = type === addRemoveType.loan ? [...emiData.loanDetails] : type === addRemoveType.card ? [...emiData.cardDetails] : type === addRemoveType.otherProperty ? [...emiData.otherProperties] : [...emiData.currentMortgage];
        newCard[index] = {
            ...newCard[index],
            [name]: value
        };
        if (type === addRemoveType.loan) {
            setemiData((prevData) => ({ ...prevData, loanDetails: newCard }));
        } else if (type === addRemoveType.card) {
            setemiData((prevData) => ({ ...prevData, cardDetails: newCard }));
        } else if (type === addRemoveType.otherProperty) {
            setemiData((prevData) => ({ ...prevData, otherProperties: newCard }));
        } else if (type === addRemoveType.mortgage) {
            setemiData((prevData) => ({ ...prevData, currentMortgage: newCard }));
        }
    }

    //handle time selection duration
    const handleTimeDurationChange = (index, value, unit) => {
        const updatedMortgage = emiData.currentMortgage.map((mortgage, mortgageIndex) => {
            if (mortgageIndex === index) {
                return {
                    ...mortgage,
                    mortgageDuration: {
                        ...mortgage.mortgageDuration,
                        [unit]: value
                    }
                };
            }
            return mortgage;
        });
        setemiData((prevData) => ({
            ...prevData,
            currentMortgage: updatedMortgage
        }));
    };


    //add and remove div - buttons
    const addRemoveButtons = (name1, name2, type) => {
        const shouldShowRemoveButton =
            (type === addRemoveType.card && emiData.cardDetails.length > 1) ||
            (type === addRemoveType.loan && emiData.loanDetails.length > 1) ||
            (type === addRemoveType.otherProperty && emiData.otherProperties.length > 1) ||
            (type === addRemoveType.mortgage && emiData.currentMortgage.length > 1);

        return (
            <div className="input-field-row">
                {shouldShowRemoveButton ? (
                    <button
                        className="removeDivButton"
                        onClick={() => removeDataDiv(type)}>
                        {name2}
                    </button>
                ) : (
                    <div className="removeDivButton"></div>
                )}
                <button
                    className="addDivButton"
                    onClick={() => addDataDiv(type)}>
                    {name1}
                </button>
            </div>
        );
    };


    //add card loan property and mortgage deatails div
    const addDataDiv = (type) => {
        if (type === addRemoveType.card) {
            setemiData((prevData) => ({
                ...prevData,
                cardDetails: [
                    ...prevData.cardDetails,
                    {
                        cardHolderName: '',
                        cardType: '',
                        bankCompanyName: '',
                        cardExpiryDate: '',
                        creditBalance: '',
                        creditLimit: '',
                    }
                ]
            }));
        } else if (type === addRemoveType.loan) {
            setemiData((prevData) => ({
                ...prevData,
                loanDetails: [
                    ...prevData.loanDetails,
                    {
                        loanType: '',
                        otherLoan: '',
                        loanProviderName: '',
                        loanAmount: '',
                        loanBalance: '',
                    }
                ]
            }))
        } else if (type == addRemoveType.otherProperty) {
            setemiData((prevData) => ({
                ...prevData,
                otherProperties: [
                    ...prevData.otherProperties,
                    {
                        propertyType: '',
                        lenderName: '',
                        propertyCurrentValue: '',
                        purchaseDate: '',
                        purchasePrice: '',
                        outstandingBalance: '',
                        propertyPaymenttype: '',
                    }
                ]
            }))
        } else if (type == addRemoveType.mortgage) {
            setemiData((prevData) => ({
                ...prevData,
                currentMortgage: [
                    ...prevData.currentMortgage,
                    {
                        propertyType: '',
                        mortgageLenderName: '',
                        monthlyPayment: '',
                        purchaseDate: '',
                        originalAmount: '',
                        mortgageDuration:
                        {
                            years: '',
                            months: '',
                            days: '',
                        },
                        interestRate: '',
                        amountLeft: '',
                        propertyCurrentValue: '',
                        mortgagePaymenttype: '',
                        arrearsCount: '',
                        PropertyAddress: '',
                    }
                ]
            }))
        }
    }

    //remove card details
    const removeDataDiv = (type) => {
        if (type === addRemoveType.card) {
        var nextCard = [...emiData.cardDetails];
        if (nextCard.length > 1) {
            nextCard = nextCard.slice(0, nextCard.length - 1);
            setemiData({ ...emiData, cardDetails: nextCard });
        }
        } else if (type === addRemoveType.loan) {
            var nextLoan = [...emiData.loanDetails];
            if (nextLoan.length > 1) {
                nextLoan = nextLoan.slice(0, nextLoan.length - 1);
                setemiData({ ...emiData, loanDetails: nextLoan });
            }
        } else if (type === addRemoveType.otherProperty) {
        var nextProperty = [...emiData.otherProperties];
        if (nextProperty.length > 1) {
            nextProperty = nextProperty.slice(0, nextProperty.length - 1);
            setemiData({ ...emiData, otherProperties: nextProperty });
        }
        } else if (type === addRemoveType.mortgage) {
            var nextMortgage = [...emiData.currentMortgage];
            if (nextMortgage.length > 1) {
                nextMortgage = nextMortgage.slice(0, nextMortgage.length - 1);
                setemiData({ ...emiData, currentMortgage: nextMortgage })
            }
        }
    }

    //add card details component
    const addCardDetails = () => {
        return (
            <>
                <p className="formHeading">Card Details</p>
                {emiData.cardDetails.map((card, index) => {
                    return <div key={index}>
                        {index > 0 && (<hr />)}
                        <p className="formHeading">{`${index + 1}. Card `}</p>
                        <InputField
                            label="Full Name on Card:"
                            value={card.cardHolderName}
                            htmlFor="cardHolderName"
                            placeholder="Enter your full name on card"
                            onChange={(e) => handleTypeSelect(index, e.target.value, e.target.id, addRemoveType.card)}
                        />
                        <SelectBox
                            label="Select Card Type:"
                            options={cardType}
                            htmlFor='cardType'
                            value={card.cardType}
                            placeholder="Select card type"
                            onChange={(value) => handleTypeSelect(index, value, 'cardType', addRemoveType.card)}
                        />
                        <InputField
                            label="Bank/Company Name:"
                            value={card.bankCompanyName}
                            htmlFor="bankCompanyName"
                            placeholder="Enter name of card provider Bank/Company"
                            onChange={(e) => handleTypeSelect(index, e.target.value, e.target.id, addRemoveType.card)}
                        />
                        <DatePickerComponent
                            label="Expiry date of Card:"
                            htmlFor="cardExpiryDate"
                            value={card.cardExpiryDate}
                            onChange={(value) => handleTypeSelect(index, value, 'cardExpiryDate', addRemoveType.card)}
                        />
                        <PriceInputBox
                            label="Outstanding Balance:"
                            htmlFor="creditBalance"
                            value={card.creditBalance}
                            onChange={(e) => handleTypeSelect(index, e.target.value, e.target.id, addRemoveType.card)}
                        />
                        <PriceInputBox
                            label="Creadit Limit:"
                            htmlFor="creditLimit"
                            value={card.creditLimit}
                            onChange={(e) => handleTypeSelect(index, e.target.value, e.target.id, addRemoveType.card)}
                        />
                    </div>
                })}
            </>
        )
    }

    //add loan details component
    const addLoanDetails = () => {
        return (
            <>
                <p className="formHeading">Loan Details</p>
                {emiData.loanDetails.map((loan, index) => {
                    return <div key={index}>
                        {index > 0 && (<hr />)}
                        <p className="formHeading">{`${index + 1}. Loan `}</p>
                        <SelectBox
                            label="Select Loan Type:"
                            placeholder="Select loan type"
                            htmlFor="loanType"
                            options={loanTypeList}
                            value={loan.loanType}
                            onChange={(value) => handleTypeSelect(index, value, 'loanType', addRemoveType.loan)}
                        />
                        {loan.loanType && loan.loanType.value === 'Other' && (
                            <InputField
                                label="Please Sepicify Loan Type:"
                                value={loan.otherLoan}
                                htmlFor="otherLoan"
                                placeholder="Enter loan type"
                                onChange={(e) => handleTypeSelect(index, e.target.value, e.target.id, addRemoveType.loan)}
                            />
                        )}
                        <InputField
                            label="Loan Provider (Name of Bank or Financial Institution)"
                            value={loan.loanProviderName}
                            htmlFor="loanProviderName"
                            placeholder="Enter name of card provider Bank/Company"
                            onChange={(e) => handleTypeSelect(index, e.target.value, e.target.id, addRemoveType.loan)}
                        />
                        <PriceInputBox
                            label="Loan Amount (Initial loan amount):"
                            htmlFor="loanAmount"
                            value={loan.loanAmount}
                            onChange={(e) => handleTypeSelect(index, e.target.value, e.target.id, addRemoveType.loan)}
                        />
                        <PriceInputBox
                            label="Outstanding Balance (Remaining loan amount):"
                            htmlFor="loanBalance"
                            value={loan.loanBalance}
                            onChange={(e) => handleTypeSelect(index, e.target.value, e.target.id, addRemoveType.loan)}
                        />
                        <PriceInputBox
                            label="Monthly Repayment (Amount you pay each month):"
                            htmlFor="monthlyLoanPayment"
                            value={loan.monthlyLoanPayment}
                            onChange={(e) => handleTypeSelect(index, e.target.value, e.target.id, addRemoveType.loan)}
                        />
                        <DatePickerComponent
                            label="Start Date of Loan:"
                            htmlFor="loanStartDate"
                            value={loan.loanStartDate}
                            onChange={(value) => handleTypeSelect(index, value, 'loanStartDate', addRemoveType.loan)}
                        />
                        <DatePickerComponent
                            label="End Date of Loan:"
                            htmlFor="loanEndDate"
                            value={loan.loanEndDate}
                            onChange={(value) => handleTypeSelect(index, value, 'loanEndDate', addRemoveType.loan)}
                        />
                    </div>
                })}
            </>
        )
    }

    const addOtherPropertyDetails = () => {
        return (
            <>
                {emiData.otherProperties.map((property, index) => {
                    return <div key={index}>
                        {index > 0 && (<hr />)}
                        <p className="formHeading">{`${index + 1}. Property `}</p>
                        <SelectBox
                            label="Select Property Type:"
                            options={propertyTypes}
                            htmlFor={`${index}propertyType`}
                            placeholder="Select property type"
                            value={property.propertyType}
                            onChange={(value) => handleTypeSelect(index, value, 'propertyType', addRemoveType.otherProperty)}
                        />
                        <InputField
                            label="Lender's Name (any bank or company name):"
                            htmlFor='lenderName'
                            value={property.lenderName}
                            placeholder="Enter lender's name (any bank or company name)"
                            onChange={(e) => handleTypeSelect(index, e.target.value, e.target.id, addRemoveType.otherProperty)}
                        />
                        <SelectBox
                            label="Mortgage / Rent Payment:"
                            htmlFor="propertyPaymenttype"
                            options={paymentStatusForProperty}
                            value={property.propertyPaymenttype}
                            placeholder="Select mortgage payment type"
                            onChange={(value) => handleTypeSelect(index, value, 'propertyPaymenttype', addRemoveType.otherProperty)}
                        />
                        <DatePickerComponent
                            label="Purchase Date:"
                            htmlFor="purchaseDate"
                            value={property.purchaseDate}
                            onChange={(value) => handleTypeSelect(index, value, 'purchaseDate', addRemoveType.otherProperty)}
                        />
                        <PriceInputBox
                            label="Price at Purchase:"
                            htmlFor="purchasePrice"
                            value={property.purchasePrice}
                            onChange={(e) => handleTypeSelect(index, e.target.value, e.target.id, addRemoveType.otherProperty)}
                        />
                        <PriceInputBox
                            label="Estimated Current Value of property:"
                            htmlFor="propertyCurrentValue"
                            value={property.propertyCurrentValue}
                            onChange={(e) => handleTypeSelect(index, e.target.value, e.target.id, addRemoveType.otherProperty)}
                        />
                        <PriceInputBox
                            label="Outstanding Balance:"
                            htmlFor="outstandingBalance"
                            value={property.outstandingBalance}
                            onChange={(e) => handleTypeSelect(index, e.target.value, e.target.id, addRemoveType.otherProperty)}
                        />
                    </div>
                })}
            </>
        )
    }

    const addCurrentMortgage = () => {
        return (
            <>
                {emiData.currentMortgage.map((mortgageProperty, index) => {
                    return <div key={index}>
                        {index > 0 && (<hr />)}
                        <p className="formHeading">{`${index + 1}. Current Mortgage `}</p>
                        <SelectBox
                            label="Select Property Type:"
                            options={propertyTypes}
                            htmlFor="propertyType"
                            placeholder="Select property type"
                            value={mortgageProperty.propertyType}
                            onChange={(value) => handleTypeSelect(index, value, 'propertyType', addRemoveType.mortgage)}
                        />
                        <InputField
                            label="Lender's Name (any bank or company name):"
                            htmlFor='mortgageLenderName'
                            value={mortgageProperty.mortgageLenderName}
                            placeholder="Enter lender's name (any bank or company name)"
                            onChange={(e) => handleTypeSelect(index, e.target.value, e.target.id, addRemoveType.mortgage)}
                        />
                        <PriceInputBox
                            label="Monthly Payment (Mortgage or Rent):"
                            htmlFor="monthlyPayment"                            
                            value={mortgageProperty.monthlyPayment}
                            onChange={(e) => handleTypeSelect(index, e.target.value, e.target.id, addRemoveType.mortgage)}
                        />
                        <DatePickerComponent
                            label="Purchase Date:"
                            htmlFor="purchaseDate"
                            value={mortgageProperty.purchaseDate}
                            onChange={(value) => handleTypeSelect(index, value, 'purchaseDate', addRemoveType.mortgage)}
                        />
                        <PriceInputBox
                            label="Original Mortgage Amount:"
                            htmlFor="originalAmount"
                            value={mortgageProperty.originalAmount}
                            onChange={(e) => handleTypeSelect(index, e.target.value, e.target.id, addRemoveType.mortgage)}
                        />
                        <TimeDuration
                            label="Mortgage Term Remaining: "
                            htmlFor={`mortgageDuration`}
                            fields={[
                                { name: 'years', value: mortgageProperty.mortgageDuration.years, options: yearsArray },
                                { name: 'months', value: mortgageProperty.mortgageDuration.months, options: monthsArray },
                                { name: 'days', value: mortgageProperty.mortgageDuration.days, options: daysArray }
                            ]}
                            onChange={(value, unit) => handleTimeDurationChange(index, value, unit)}
                        />
                        <PercentageInputBox
                            label="Mortgage Interest Rate:"
                            htmlFor="interestRate"
                            value={mortgageProperty.interestRate}
                            onChange={(e) => handleTypeSelect(index, e.target.value, e.target.id, addRemoveType.mortgage)}
                        />
                        <PriceInputBox
                            label="Mortgage Amount Left:"
                            htmlFor="amountLeft"
                            value={mortgageProperty.amountLeft}
                            onChange={(e) => handleTypeSelect(index, e.target.value, e.target.id, addRemoveType.mortgage)}
                        />
                        <PriceInputBox
                            label="Current Value of property:"
                            htmlFor="propertyCurrentValue"
                            value={mortgageProperty.propertyCurrentValue}
                            onChange={(e) => handleTypeSelect(index, e.target.value, e.target.id, addRemoveType.mortgage)}
                        />
                        <SelectBox
                            label="Repayment / Interest only:"
                            htmlFor="mortgagePaymenttype"
                            options={paymentStatusForMortgage}
                            value={mortgageProperty.mortgagePaymentStatus}
                            placeholder="Select mortgage payment type"
                            onChange={(value) => handleTypeSelect(index, value, 'mortgagePaymenttype', addRemoveType.mortgage)}
                        />
                        <SelectBox
                            label="Arrears in last 12 months:"
                            htmlFor="arrearsCount"
                            options={countArray}
                            value={mortgageProperty.arrearsCount}
                            placeholder='Select number of times you missed mortgage payment'
                            onChange={(value) => handleTypeSelect(index, value, 'arrearsCount', addRemoveType.mortgage)}
                        />
                        <InputField
                            label="Address:"
                            htmlFor='PropertyAddress'
                            value={mortgageProperty.propertyAddress}
                            placeholder="Enter address"
                            onChange={(e) => handleTypeSelect(index, e.target.value, e.target.id, addRemoveType.mortgage)}
                        />
                    </div>
                })}
            </>
        )
    }

    return (
        <>
            <div className="personal-details-form">
                <div className="form_name ">Credit Card And Loan Details</div>
                <SelectBox
                    label="Expense Type:"
                    htmlFor='expenseType'
                    options={emiType}
                    value={emiData.expenseType}
                    placeholder="Select expense type"
                    onChange={(value) => handleOnChange(value, 'expenseType')}
                />
                {emiData.expenseType && emiData.expenseType.value === 'Credit Card' && (
                    <>
                        <hr />
                        {addCardDetails()}
                        {addRemoveButtons('Add another Card', 'Remove Card', addRemoveType.card)}
                    </>
                )}

                {emiData.expenseType && emiData.expenseType.value === 'Loan' && (
                    <>
                        <hr />
                        {addLoanDetails()}
                        {addRemoveButtons('Add another Loan Details', 'Remove Loan details', addRemoveType.loan)}
                    </>
                )}
                {emiData.expenseType && emiData.expenseType.value === 'Both' && (
                    <>
                        <hr />
                        {addCardDetails()}
                        {addRemoveButtons('Add another Card', 'Remove Card', addRemoveType.card)}
                        <hr />
                        {addLoanDetails()}
                        {addRemoveButtons('Add another Loan Details', 'Remove Loan details', addRemoveType.loan)}
                    </>
                )}

                <hr />
                <p className="formHeading">Current Mortgage Details</p>
                <SelectBox
                    label="Current Mortgage:"
                    options={yesNoArray}
                    htmlFor="currentMortgageStatus"
                    value={emiData.currentMortgageStatus}
                    placeholder="Do you have any current mortgage?"
                    onChange={(value) => handleOnChange(value, 'currentMortgageStatus')}
                />
                {emiData.currentMortgageStatus && emiData.currentMortgageStatus.value === 'Yes' && (
                    <>
                        {addCurrentMortgage()}
                        {addRemoveButtons('Add More Current Mortgage', 'Remove Current Mortgage', addRemoveType.mortgage)}
                    </>
                )}
                <hr />
                <p className="formHeading">Other Property Details</p>
                <SelectBox
                    label="Do You Own Any other Property:"
                    options={yesNoArray}
                    placeholder="Select if you have any other property"
                    value={emiData.otherPropertyStatus}
                    onChange={(value) => handleOnChange(value, 'otherPropertyStatus')}
                />
                {emiData.otherPropertyStatus && emiData.otherPropertyStatus.value === 'Yes' && (
                    <>
                        {addOtherPropertyDetails()}
                        {addRemoveButtons('Add More Properties', 'Remove Property', addRemoveType.otherProperty)}
                    </>
                )}

                <hr />
                <p className="formHeading">Main Bank Account Details</p>
                <InputField
                    label="Main Bank Account Name:"
                    placeholder="Enter main bank account name"
                    value={emiData.mainAccountName}
                    htmlFor="mainAccountName"
                    onChange={(event) => handleOnChange(event.target.value, event.target.id)}
                />
                <DatePickerComponent
                    label="Date Bank Account Opened?"
                    htmlFor="AccountOpenDate"
                    value={emiData.AccountOpenDate}
                    onChange={(value) => handleOnChange(value, "AccountOpenDate")}
                />
                <InputField
                    label="Bank sort Code:"
                    placeholder="Enter bank sort code"
                    value={emiData.bankSortCode}
                    htmlFor="bankSortCode"
                    onChange={(event) => handleOnChange(event.target.value, event.target.id)}
                />
                <InputField
                    label="Bank account number:"
                    placeholder="Enter bank account number"
                    value={emiData.accountNumber}
                    htmlFor="accountNumber"
                    onChange={(event) => handleOnChange(event.target.value, event.target.id)}
                />
                <InputField
                    label="Bank Address:"
                    placeholder="Enter bank address"
                    value={emiData.bankAddress}
                    htmlFor="bankAddress"
                    onChange={(event) => handleOnChange(event.target.value, event.target.id)}
                />
                <SelectBox
                    label="Use this Account for New Finance Payments:"
                    options={yesNoArray}
                    value={emiData.newPaymentStatus}
                    placeholder="Select an option"
                    onChange={(value) => handleOnChange(value, 'newPaymentStatus')}
                />

            </div>
        </>
    )
}

export default EmiDetails;