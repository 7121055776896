import React, { useState, useMemo } from 'react'
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { SlCalender } from "react-icons/sl";
import { MdCurrencyPound } from "react-icons/md";
import { MdOutlinePercent } from "react-icons/md";
// InputField Component
export const InputField = ({ label, htmlFor, placeholder, value, onChange, disabled }) => (
    <div key={label} className="input-field-row">
        <div className="label_div"><label htmlFor={htmlFor}>{label}</label></div>
        <div className="input_box_div">
            <input
                className="input_style"
                type="text"
                id={htmlFor}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                disabled={disabled}
            />
        </div>
    </div>
);

// InputFieldWithDate Component
export const InputFieldWithDate = ({ label, htmlFor, placeholder1, nameValue, dateValue, onChange, onDateChange }) => {
    return (
        <div key={label} className="input-field-row">
            <div className="label_div"><label htmlFor={htmlFor}>{label}</label></div>
            <div className="date_picker_data_div">
                <div className="name_div">
                    <input
                        className="input_style"
                        type="text"
                        id={htmlFor}
                        placeholder={placeholder1}
                        value={nameValue}
                        onChange={onChange}
                    />
                </div>
                <div className="date_div">
                    <DatePicker
                        value={dateValue}
                        onChange={onDateChange}
                        showLeadingZeros
                        format="dd/MM/yyyy"
                        dayPlaceholder='DD'
                        monthPlaceholder='MM'
                        yearPlaceholder='YYYY'
                        clearIcon={<div />}
                        calendarIcon={<SlCalender style={{ fontSize: '15px', color: 'gray' }} />}
                    />
                </div>
            </div>
        </div>
    );
};

// ButtonComponent
export const ButtonComponent = ({ name, onClick }) => (
    <div className="btn-div">
        <button type="button" className="proceed-btn" onClick={onClick}>
            {name}
        </button>
    </div>
);

// DatePicker Component
export const DatePickerComponent = ({ htmlFor, label, placeholder, value, onChange }) => {
    return (
        <div className="input-field-row">
            <div className='label_div'> <label htmlFor={htmlFor}>{label}</label></div>
            <div className="calender_div">
                <DatePicker
                    value={value}
                    onChange={onChange}
                    showLeadingZeros
                    format="dd/MM/yyyy"
                    dayPlaceholder=' DD '
                    monthPlaceholder=' MM '
                    yearPlaceholder=' YYYY '
                    clearIcon={<div />}
                    calendarIcon={<SlCalender style={{ fontSize: '15px', color: 'gray' }} />}
                />
            </div>
        </div>
    );
};

// SelectBox Component
export const SelectBox = ({ label, htmlFor, options, name, onChange, value, placeholder }) => {
    return (
        <div className="input-field-row">
            <div className="label_div">
                <label htmlFor={htmlFor}>{label}</label>
            </div>
            <div className="input_box_div">
                <Select options={options}
                    value={value}
                    onChange={(selectedOption) => onChange(selectedOption)}
                    name={name}
                    placeholder={placeholder}
                />
            </div>
        </div>
    );
};

//address durations 
export const TimeDuration = ({ label, htmlFor, fields, onChange }) => {
    const renderSelect = (name, value, optionArray, index) => (
        <Select
            key={index}
            className="time_duration"
            name={name}
            onChange={(selectedOption) => onChange(selectedOption, name)}
            value={value}
            placeholder={name}
            options={optionArray}
        />
    );
    return (
        <div className="input-field-row">
            <div className="label_div">
                <label htmlFor={htmlFor}>{label}</label>
            </div>
            <div className="time_duration_div">
                {fields.map((field, index) =>
                    renderSelect(field.name, field.value, field.options, index)
                )}
            </div>
        </div>
    );
};

//country picker
export const CountryPicker = () => {
    const [value, setValue] = useState('')
    const options = useMemo(() => countryList().getData(), [])

    const changeHandler = value => {
        console.log("country name", countryList().getValue(value));
        setValue(countryList().getValue(value))
    }
    return <Select options={options} value={value} onChange={changeHandler} />
};


export const PriceInputBox = ({ label, htmlFor, placeholder, value, onChange }) => (
    <div key={label} className="input-field-row">
        <div className="label_div"><label htmlFor={htmlFor}>{label}</label></div>
        <div className="input_box_div">
            <div className="input_container">
                <span className="currency_icon"><MdCurrencyPound style={{ color: 'gray' }} /></span>
                <input
                    style={{ paddingLeft: '15px' }}
                    className="input_style"
                    type="text"
                    id={htmlFor}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                />
            </div>
        </div>
    </div>
);

export const PercentageInputBox = ({ label, htmlFor, placeholder, value, onChange }) => (
    <div key={label} className="input-field-row">
        <div className="label_div"><label htmlFor={htmlFor}>{label}</label></div>
        <div className="input_box_div">
            <div className="input_container">
                <span className="percentage_icon"><MdOutlinePercent color='gray' /></span>
                <input
                    style={{ paddingLeft: '15px' }}
                    className="input_style"
                    type="text"
                    id={htmlFor}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                />
            </div>
        </div>
    </div>
);
