import React, { useState, useEffect, useMemo } from "react";
import '../style/personaldetailform.css';
import { InputField, InputFieldWithDate, SelectBox, ButtonComponent, DatePickerComponent, TimeDuration } from "../component/Common";
import { addressStatusArray, daysArray, gender, maritalStatusOptions, monthsArray, NameChangeStatus, NamePrefix, otherOptionsArray, previousAddressStatusArray, SelectDependants, visaTypeArray, votersRoll, yearsArray, yesNoArray } from "../constants/form_constatnts";

import countryList from 'react-select-country-list';
import { calculateYearsDurations } from "../utils/utils";
const PersonalDetails = ({ onNextPage }) => {
    const nationalityOptions = useMemo(() => countryList().getData(), [])
    const [formData, setFormData] = useState({
        title: '',
        otherTitle: '',
        forename: '',
        surname: '',
        nameStatus: '',
        previousName: '',
        previousDate: null,
        dateOfBirth: null,
        genderStatus: '',
        motherMaidenName: '',
        homePhone: '',
        mobilePhone: '',
        workPhone: '',
        email: '',
        maritalStatus: '',
        dependantsCount: '',
        dependants: [],
        nationality: '',
        residency: '',
        visa: '',
        smokersStatus: '',
        alcohalStatus: '',
        passportStatus: '',
        passportIssuingCountry: '',
        PassportIssueDate: '',
        PassportExpiryDate: '',
        voterRollStatus: '',
        currentAddress: '',
        addressStatus: '',
        current_address_duration: {
            years: '',
            months: '',
            days: ''
        },
        previousAddresses: [
            {
                address: '',
                addressStatus: '',
                duration: {
                    years: '',
                    months: '',
                    days: ''
                }
            }
        ]
    });

    // Retrieve form values from local storage on component mount
    useEffect(() => {
        const storedData = localStorage.getItem("formValues");
        if (storedData) {
            setFormData(JSON.parse(storedData));
        }
    }, []);

    // Handle change for text inputs
    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [id]: value }));
    };

    // Handle change for select inputs
    const handleSelectChange = (value, name) => {
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    // Handle date change
    const handleDateChange = (date, name) => {
        setFormData((prevData) => ({ ...prevData, [name]: date }));
    };

    // Handle form submission
    const handleFormSubmission = () => {        
        onNextPage()
    };

    // handle address duration select
    const handleSelection = (value, name) => {
        setFormData((prevData) => ({
            ...prevData,
            current_address_duration: {
                ...prevData.current_address_duration,
                [name]: value
            }
        }));
        if (name === "years" && value.value >= 3) {
            setFormData((prevData) => ({
                ...prevData,
                previousAddresses: [{
                    address: '',
                    addressStatus: '',
                    duration: {
                        years: '',
                        months: '',
                        days: ''
                    }
                }
                ]
            }));
        }
    };

    //handle previous address details
    const handlePreviousAddressChange = (index, name, value) => {
        const updatedAddresses = [...formData.previousAddresses];
        updatedAddresses[index] = {
            ...updatedAddresses[index],
            [name]: value
        };
        setFormData({ ...formData, previousAddresses: updatedAddresses });

    };

    //handle previous address durations details
    const handlePreviousAddressDurationChange = (index, value, unit) => {
        const updatedAddresses = [...formData.previousAddresses];
        updatedAddresses[index] = {
            ...updatedAddresses[index],
            duration: {
                ...updatedAddresses[index].duration,
                [unit]: value
            }
        };

        setFormData({ ...formData, previousAddresses: updatedAddresses });
        const durationObj = updatedAddresses[index].duration;
        if (durationObj.years !== '' && durationObj.months !== '' && durationObj.days !== '' && calculateYearsDurations(formData.current_address_duration, formData.previousAddresses) != null && calculateYearsDurations(formData.current_address_duration, formData.previousAddresses).years < 3) {
            addPreviousAddress()
        } else {
            let cumulativeDuration = calculateYearsDurations(formData.current_address_duration, updatedAddresses.slice(0, index + 1));

            const filteredAddresses = updatedAddresses.filter((address, idx) => {
                if (idx <= index) {
                    return true;
                } else {

                    const durationToAdd = calculateYearsDurations(formData.current_address_duration, [address]);
                    cumulativeDuration.years += durationToAdd.years;
                    cumulativeDuration.months += durationToAdd.months;
                    cumulativeDuration.days += durationToAdd.days;
                    // Normalize days and months for proper year calculation
                    if (cumulativeDuration.days >= 30) {
                        cumulativeDuration.months += Math.floor(cumulativeDuration.days / 30);
                        cumulativeDuration.days = cumulativeDuration.days % 30;
                    }
                    if (cumulativeDuration.months >= 12) {
                        cumulativeDuration.years += Math.floor(cumulativeDuration.months / 12);
                        cumulativeDuration.months = cumulativeDuration.months % 12;
                    }
                    return cumulativeDuration.years < 3;
                }
            });

            setFormData({ ...formData, previousAddresses: filteredAddresses });
        }
    };

    const addPreviousAddress = () => {
        setFormData((prevData) => ({
            ...prevData,
            previousAddresses: [
                ...prevData.previousAddresses,
                {
                    address: '',
                    addressStatus: '',
                    duration: {
                        years: '',
                        months: '',
                        days: ''
                    }
                }
            ]
        }));
    };  

    const personaldetailform = () => {
        return (
            <div className="personal-details-form">
                <div className="form_name ">Personal Details</div>
                <SelectBox
                    label="Title"
                    options={NamePrefix}
                    htmlFor="name-title"
                    name="title"
                    value={formData.title}
                    placeholder='Select title'
                    onChange={(value) => handleSelectChange(value, 'title')}
                />
                {formData.title.value === 'Other' &&
                    <SelectBox
                        label="Other Title"
                        placeholder='Please select a title'
                        options={otherOptionsArray}
                        htmlFor="other-title"
                        name="otherTitle"
                        value={formData.otherTitle}
                        onChange={(value) => handleSelectChange(value, 'otherTitle')
                        }
                    />}
                <InputField
                    label="Forename(s):"
                    htmlFor="forename"
                    placeholder="Enter your forename(s)"
                    value={formData.forename}
                    onChange={handleInputChange}
                />
                <InputField
                    label="Surname:"
                    htmlFor="surname"
                    placeholder="Enter your surname"
                    value={formData.surname}
                    onChange={handleInputChange}
                />
                <SelectBox
                    label="Have you ever changed your name ?"
                    options={NameChangeStatus}
                    htmlFor="name-change-status"
                    name="nameStatus"
                    value={formData.nameStatus}
                    placeholder="Select a value"
                    onChange={(value) => handleSelectChange(value, 'nameStatus')}
                />
                {formData.nameStatus.value === 'Yes' && (
                    <InputFieldWithDate
                        label="Previous name(s) and dates of change:"
                        htmlFor="previousName"
                        placeholder1="Enter previous name(s)"
                        placeholder2="Select date of name change"
                        nameValue={formData.previousName}
                        dateValue={formData.previousDate}
                        onChange={handleInputChange}
                        onDateChange={(date) => handleDateChange(date, 'previousDate')}
                    />
                )}
                <DatePickerComponent
                    label="Date of Birth"
                    htmlFor="date-of-birth"
                    placeholder="Select your date of birth"
                    value={formData.dateOfBirth}
                    onChange={(date) => handleDateChange(date, 'dateOfBirth')}
                />
                <SelectBox
                    label="Gender"
                    htmlFor="gender"
                    placeholder="Select gender"
                    options={gender}
                    name="genderStatus"
                    value={formData.genderStatus}
                    onChange={(value) => handleSelectChange(value, 'genderStatus')}
                />
                <InputField
                    label="Mother's Maiden Name:"
                    htmlFor="motherMaidenName"
                    placeholder="Enter mother's maiden name"
                    value={formData.motherMaidenName}
                    onChange={handleInputChange}
                />
                <InputField
                    label="Home Telephone No:"
                    htmlFor="homePhone"
                    placeholder="Enter home telephone number"
                    value={formData.homePhone}
                    onChange={handleInputChange}
                />
                <InputField
                    label="Mobile Telephone No:"
                    htmlFor="mobilePhone"
                    placeholder="Enter mobile number"
                    value={formData.mobilePhone}
                    onChange={handleInputChange}
                />
                <InputField
                    label="Work Telephone No:"
                    htmlFor="workPhone"
                    placeholder="Enter work telephone number"
                    value={formData.workPhone}
                    onChange={handleInputChange}
                />
                <InputField
                    label="Email Address:"
                    htmlFor="email"
                    placeholder="Enter email address"
                    value={formData.email}
                    onChange={handleInputChange}
                />
                <SelectBox
                    label="Marital Status:"
                    htmlFor="marital-status"
                    options={maritalStatusOptions}
                    placeholder="Select marital status"
                    name="maritalStatus"
                    value={formData.maritalStatus}
                    onChange={(value) => handleSelectChange(value, 'maritalStatus')}
                />                
                <SelectBox
                    label="Do you Smoke ?"
                    options={yesNoArray}
                    htmlFor='smokers-status'
                    name="smokers"
                    value={formData.smokersStatus}
                    onChange={(value) => handleSelectChange(value, 'smokersStatus')}
                    placeholder="Select an option"
                />
                <SelectBox
                    label="Do you Drink Alcohal ?"
                    options={yesNoArray}
                    htmlFor='alcohal-status'
                    name="alcohal"
                    value={formData.alcohalStatus}
                    onChange={(value) => handleSelectChange(value, 'alcohalStatus')}
                    placeholder="Select an option"
                />
                <SelectBox
                    label="Nationality"
                    htmlFor="nationality"
                    placeholder='Select nationality'
                    options={nationalityOptions}
                    value={formData.nationality}
                    name="nationality"
                    onChange={(value) => handleSelectChange(value, 'nationality')}
                />

                {formData.nationality.label && formData.nationality.label !== 'United Kingdom' && (
                    < SelectBox
                        label="Visa Type"
                        htmlFor="visa"
                        placeholder="Select visa type"
                        options={visaTypeArray}
                        name='visa_type'
                        value={formData.visaType}
                        onChange={(value) => handleSelectChange(value, 'visaType')}
                    />
                )}
                <InputField
                    label="Residency status in UK:"
                    htmlFor="residency"
                    placeholder="Enter residency status"
                    value={formData.residency}
                    onChange={handleInputChange}
                />
                <SelectBox
                    label="Currently on voters roll ?"
                    options={votersRoll}
                    name="voterRollStatus"
                    value={formData.voterRollStatus}
                    placeholder="Select an option"
                    onChange={(value) => handleSelectChange(value, 'voterRollStatus')}
                />
                <hr />
                <p className="formHeading">Dependants Details</p>
                <SelectBox
                    label="Select Number of Dependants:"
                    htmlFor="no-of-dependants"
                    options={SelectDependants}
                    value={formData.dependantsCount}
                    placeholder="Select no. of dependants"
                    name="dependantsCount"
                    onChange={(value) => handleSelectChange(value, 'dependantsCount')}
                />
                {formData.dependantsCount && (
                    [...Array(parseInt(formData.dependantsCount.value))].map((_, i) => (
                        <InputFieldWithDate
                            key={i}
                            label={`Dependant ${i + 1}:`}
                            htmlFor={`dependantName_${i + 1}`}
                            placeholder1={`Enter name of dependant ${i + 1}`}
                            value={formData.dependants[i]?.name || ''}
                            dateValue={formData.dependants[i]?.date || null}
                            onChange={(e) => {
                                const updatedDependants = [...formData.dependants];
                                updatedDependants[i] = { ...updatedDependants[i], name: e.target.value };
                                setFormData((prevData) => ({ ...prevData, dependants: updatedDependants }));
                            }}
                            onDateChange={(date) => {
                                const updatedDependants = [...formData.dependants];
                                updatedDependants[i] = { ...updatedDependants[i], date };
                                setFormData((prevData) => ({ ...prevData, dependants: updatedDependants }));
                            }}
                        />
                    ))
                )}

                <hr />
                <p className="formHeading">Passport Details</p>
                <SelectBox
                    label="Do you have Passport ?"
                    options={yesNoArray}
                    htmlFor='passport-status'
                    name="passport"
                    placeholder="Select an option"
                    value={formData.passportStatus}
                    onChange={(value) => handleSelectChange(value, 'passportStatus')}
                />
                {formData.passportStatus.value === 'Yes' && (
                    <>
                        <SelectBox
                            label="Select Passport Issuing Country:"
                            htmlFor="passport_issuing_country"
                            placeholder='Select passport issuing country'
                            options={nationalityOptions}
                            value={formData.passportIssuingCountry}
                            name="passport_country"
                            onChange={(value) => handleSelectChange(value, 'passportIssuingCountry')}
                        />
                        <DatePickerComponent
                            label="Passport Issue Date:"
                            htmlFor="passport_issue_date"
                            placeholder="Select passport issue date"
                            value={formData.PassportIssueDate}
                            onChange={(date) => handleDateChange(date, 'PassportIssueDate')}
                        />
                        <DatePickerComponent
                            label="Passport Expiry Date:"
                            htmlFor="passport_expiry_date"
                            placeholder="Select passport expiry date"
                            value={formData.PassportExpiryDate}
                            onChange={(date) => handleDateChange(date, 'PassportExpiryDate')}
                        />
                    </>
                )}
                <>
                    <hr />
                    <p className="formHeading">Current Details ( Minimum 3 years of address history is required)</p>
                    <InputField
                        label="Current address:"
                        htmlFor="currentAddress"
                        placeholder="Enter current address"
                        value={formData.currentAddress}
                        onChange={handleInputChange}
                    />
                    <SelectBox
                        label="Status of current address:"
                        placeholder="Select status of current address"
                        options={addressStatusArray}
                        name="addressStatus"
                        value={formData.addressStatus}
                        onChange={(value) => handleSelectChange(value, 'addressStatus')}
                    />
                    <TimeDuration
                        label="Duration of stay at current address:"
                        htmlFor="current_address_duration"
                        fields={[
                            { name: 'years', value: formData.current_address_duration.years, options: yearsArray },
                            { name: 'months', value: formData.current_address_duration.months, options: monthsArray },
                            { name: 'days', value: formData.current_address_duration.days, options: daysArray }
                        ]}
                        onChange={handleSelection}
                    />
                </>
                {formData.current_address_duration.days.value && parseInt(formData.current_address_duration.years.value) < 3 &&
                    formData.previousAddresses.map((address, index) => (
                        <div key={index} className="previous_add">
                            <hr />
                            <p className="formHeading">{index + 1}. Previous Details</p>
                            <InputField
                                label={`Previous Address:`}
                                htmlFor={`previousAddress_${index}`}
                                placeholder="Enter previous address"
                                value={address.address}
                                onChange={(e) => handlePreviousAddressChange(index, 'address', e.target.value)}
                            />
                            <SelectBox
                                label="Status of previous address:"
                                placeholder="Select status of previous address"
                                options={previousAddressStatusArray}
                                name="previousAddressStatus"
                                value={address.addressStatus}
                                onChange={(value) => handlePreviousAddressChange(index, 'addressStatus', value)}
                            />
                            <TimeDuration
                                label="Duration of stay at previous address:"
                                htmlFor={`previous_address_duration_${index}`}
                                fields={[
                                    { name: 'years', value: address.duration.years, options: yearsArray },
                                    { name: 'months', value: address.duration.months, options: monthsArray },
                                    { name: 'days', value: address.duration.days, options: daysArray }
                                ]}
                                onChange={(value, unit) => handlePreviousAddressDurationChange(index, value, unit)}
                            />
                        </div>
                    ))
                }                
                <ButtonComponent label="Submit" name='Proceed' onClick={handleFormSubmission} />
            </div>
        );
    };
    return (
        <>
            {personaldetailform()}
        </>
    );
};

export default PersonalDetails;