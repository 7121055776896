export const NamePrefix = [
    { label: 'Mr', value: 'Mr' },
    { label: 'Mrs', value: 'Mrs' },
    { label: 'Miss', value: 'Miss' },
    { label: 'Ms', value: 'Ms' },
    { label: 'Mx', value: 'Mx' },
    { label: 'Doctor', value: 'Doctor' },
    { label: 'Other', value: 'Other' },
]

export const NameChangeStatus = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
];

export const gender = [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
    { label: 'Others', value: 'Others' },
];

export const maritalStatusOptions = [
    { label: 'Unmarried', value: 'Unmarried' },
    { label: 'Married', value: 'Married' },
    { label: 'Divorced', value: 'Divorced' },
    { label: 'Separated', value: 'Separated' },
    { label: 'Widowed', value: 'Widowed' },
    { label: 'Living As Partners', value: 'Living As Partners' },
];

export const votersRoll = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
];

export const yesNoArray = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
];

export const SelectDependants = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
    { label: '9', value: 9 },
    { label: '10', value: 10 },
];

export const visaTypeArray = [
    { label: 'Business visa', value: 'Business visa' },
    { label: 'Family visa', value: 'Family visa' },
    { label: 'Student visa', value: 'Student visa' },
    { label: 'Refugee visa', value: 'Refugee visa' },
    { label: 'British National (Overseas) Visa', value: 'British National (Overseas) Visa' },
];

export const addressStatusArray = [
    { label: 'Owner', value: 'Owner' },
    { label: 'Renting', value: 'Renting' },
    { label: 'Living with parents', value: 'Living with parents' },
    { label: 'Other', value: 'Other' },
];

export const previousAddressStatusArray = [
    { label: 'Owner', value: 'Owner' },
    { label: 'Renting', value: 'Renting' },
    { label: 'Living with parents', value: 'Living with parents' },
    { label: 'Other', value: 'Other' },
];

export const yearsArray = [
    { label: '0 year', value: '0' },
    { label: '1 year', value: '1' },
    ...Array.from({ length: 29 }, (_, i) => ({
        label: `${i + 2} years`,
        value: `${i + 2}`
    })),
    { label: 'more than 30 years', value: 'more than 30 years' }
];

export const monthsArray = [
    { label: '0 month', value: '0' },
    { label: '1 month', value: '1' },
    ...Array.from({ length: 10 }, (_, i) => ({
        label: `${i + 2} months`,
        value: `${i + 2}`
    }))
];

export const daysArray = [
    { label: '0 day', value: '0' },
    { label: '1 day', value: '1' },
    ...Array.from({ length: 30 }, (_, i) => ({
        label: `${i + 2} days`,
        value: `${i + 2}`
    }))
];

export const countArray = [
    { label: '0 times', value: '0' },
    { label: '1 times', value: '1' },
    ...Array.from({ length: 11 }, (_, i) => ({
        label: `${i + 2} times`,
        value: `${i + 2}`
    }))
];

export const paymentStatusForMortgage = [
    { label: 'Interest Only', value: 'Interest Only' },
    { label: 'Repayment', value: 'Repayment' },
]

export const otherPropertyStatus = [
    { label: 'Mortgage', value: 'Mortgage' },
    { label: 'Rent Payment', value: 'Rent Payment' },
    { label: 'Neither', value: 'Neither' },
]

export const employmentStatus = [
    { label: 'Employed', value: 'Employed' },
    { label: 'Self-Employed', value: 'Self-Employed' },
    { label: 'Both', value: 'Both' },
    { label: 'Neither', value: 'Neither' },
]

export const employmentTypeList = [
    { label: 'Permanent', value: 'Permanent' },
    { label: 'Agency', value: 'Agency' },
    { label: 'Contract', value: 'Contract' },
]

export const otherIncomeList = [
    { label: 'Overtime', value: 'Overtime' },
    { label: 'Commission', value: 'Commission' },
    { label: 'Allowances', value: 'Allowances' },
    { label: 'Bonuses', value: 'Bonuses' },
    { label: 'Pension', value: 'Pension' },
    { label: 'Investments', value: 'Investments' },
    { label: 'Maintenance', value: 'Maintenance' },
    { label: 'Trust fund', value: 'Trust fund' },
    { label: 'Disability benefits', value: 'Disability benefits' },
    { label: 'Other State benefits', value: 'Other State benefits' },
]

export const universalBenifitsList = [
    { label: 'Child Tax Credit:', value: 'Child Tax Credit:' },
    { label: 'Child Benefit:', value: 'Child Benefit:' },
    { label: 'Other Benefits i.e DLA/CA/EESA', value: 'Other Benefits' },
]

export const businessRoles = [
    { label: 'Sole Trader', value: 'Sole Trader' },
    { label: 'Partner', value: 'Partner' },
    { label: 'Shareholder', value: 'Shareholder' },
]

export const emiType = [
    { label: 'Credit Card', value: 'Credit Card' },
    { label: 'Loan', value: 'Loan' },
    { label: 'Both', value: 'Both' },
    { label: 'Neither', value: 'Neither' },
]

export const cardType = [
    { label: 'Visa', value: 'Visa' },
    { label: 'MasterCard', value: 'MasterCard' },
    { label: 'American Express', value: 'American Express' },
    { label: 'Discover', value: 'Discover' },
    { label: 'Diners Club', value: 'Diners Club' }
];

export const loanTypeList = [
    { label: 'Personal Loan', value: 'Personal Loan' },
    { label: 'Car Loan', value: 'Car Loan' },
    { label: 'Home Improvement Loan', value: 'Home Improvement Loan' },
    { label: 'Debt Consolidation Loan', value: 'Debt Consolidation Loan' },
    { label: 'Business Loan', value: 'Business Loan' },
    { label: 'Education Loan', value: 'Education Loan' },
    { label: 'Guarantor Loan', value: 'Guarantor Loan' },
    { label: 'Payday Loan', value: 'Payday Loan' },
    { label: 'Other', value: 'Other' },
];

export const insuranceType = [
    { label: 'Health Insurance', value: 'health' },
    { label: 'Life Insurance', value: 'life' },
    { label: 'Car Insurance', value: 'car' },
    { label: 'Home Insurance', value: 'home' },
    { label: 'Travel Insurance', value: 'travel' },
    { label: 'Pet Insurance', value: 'pet' },
    { label: 'Income Protection Insurance', value: 'income_protection' },
    { label: 'Critical Illness Insurance', value: 'critical_illness' },
    { label: 'Business Insurance', value: 'business' },
    { label: 'Landlord Insurance', value: 'landlord' }
];

export const propertyTypes = [
    { label: 'Office', value: 'office' },
    { label: '1BHK (1 Bedroom Hall Kitchen)', value: '1bhk' },
    { label: '2BHK (2 Bedroom Hall Kitchen)', value: '2bhk' },
    { label: '3BHK (3 Bedroom Hall Kitchen)', value: '3bhk' },
    { label: 'Studio Apartment', value: 'studio_apartment' },
    { label: 'Penthouse', value: 'penthouse' },
    { label: 'Retail/Shop', value: 'retail_shop' },
    { label: 'Co-Working Space', value: 'co_working_space' },
    { label: 'Warehouse', value: 'warehouse' },
    { label: 'Loft', value: 'loft' }
];

export const addRemoveType = {
    loan: 'loan',
    card: 'card',
    otherProperty: 'otherProperty',
    mortgage: 'mortgage',
}
export const paymentStatusForProperty = [
    { value: "Mortgage", label: "Mortgage" },
    { value: "Rent", label: "Rent" },
    { value: "Neither", label: "Neither" },
]

export const occupationList = [
    { value: "Accountancy", label: "Accountancy" },
    { value: "Aerospace", label: "Aerospace" },
    { value: "Agriculture, Fishing, Forestry", label: "Agriculture, Fishing, Forestry" },
    { value: "Airlines", label: "Airlines" },
    { value: "Automotive (including Car Sales)", label: "Automotive (including Car Sales)" },
    { value: "Banking, Insurance, Finance", label: "Banking, Insurance, Finance" },
    { value: "Carpenter", label: "Carpenter" },
    { value: "Catering And Hospitality", label: "Catering And Hospitality" },
    { value: "Charity Worker", label: "Charity Worker" },
    { value: "Construction", label: "Construction" },
    { value: "Contractors", label: "Contractors" },
    { value: "Council Worker", label: "Council Worker" },
    { value: "Creative / Specialist", label: "Creative / Specialist" },
    { value: "Customer Services, Call Centres", label: "Customer Services, Call Centres" },
    { value: "Driver", label: "Driver" },
    { value: "Education", label: "Education" },
    { value: "Electrician", label: "Electrician" },
    { value: "Electronics", label: "Electronics" },
    { value: "Emergency Services", label: "Emergency Services" },
    { value: "Engineering/manufacturing", label: "Engineering/manufacturing" },
    { value: "Estate Agents", label: "Estate Agents" },
    { value: "Film/tv", label: "Film/tv" },
    { value: "Graduate", label: "Graduate" },
    { value: "Health (including Nhs)", label: "Health (including Nhs)" },
    { value: "Hm Forces", label: "Hm Forces" },
    { value: "Human Resources", label: "Human Resources" },
    { value: "It And Internet", label: "It And Internet" },
    { value: "Landscaping", label: "Landscaping" },
    { value: "Legal", label: "Legal" },
    { value: "Management Consultancy", label: "Management Consultancy" },
    { value: "Manager", label: "Manager" },
    { value: "Marketing, Advertising, Pr", label: "Marketing, Advertising, Pr" },
    { value: "Media And Creative", label: "Media And Creative" },
    { value: "Nurse", label: "Nurse" },
    { value: "Office Staff / Clerical", label: "Office Staff / Clerical" },
    { value: "Office Worker", label: "Office Worker" },
    { value: "Oil, Gas, Alternative Energy", label: "Oil, Gas, Alternative Energy" },
    { value: "Other", label: "Other" },
    { value: "Painting And Decorating", label: "Painting And Decorating" },
    { value: "Plastering", label: "Plastering" },
    { value: "Plumbing", label: "Plumbing" },
    { value: "Professional", label: "Professional" },
    { value: "Property", label: "Property" },
    { value: "Public Sector Services", label: "Public Sector Services" },
    { value: "Recruitment Sales", label: "Recruitment Sales" },
    { value: "Retail", label: "Retail" },
    { value: "Sales Assistant / Service Staff", label: "Sales Assistant / Service Staff" },
    { value: "Sales Person", label: "Sales Person" },
    { value: "Science", label: "Science" },
    { value: "Senior Executive", label: "Senior Executive" },
    { value: "Skilled Worker", label: "Skilled Worker" },
    { value: "Social Services", label: "Social Services" },
    { value: "Sport", label: "Sport" },
    { value: "Teacher", label: "Teacher" },
    { value: "Telecomms", label: "Telecomms" },
    { value: "Transport And Logistics", label: "Transport And Logistics" },
    { value: "Travel, Leisure, Tourism", label: "Travel, Leisure, Tourism" },
    { value: "Unskilled Worker", label: "Unskilled Worker" },
    { value: "Utilities", label: "Utilities" }
];

export const otherOptionsArray = [
    { value: "ASMN", label: "Able Seaman" },
    { value: "ACNT", label: "Accountant" },
    { value: "ADMN", label: "Administrator" },
    { value: "ADML", label: "Admiral" },
    { value: "ADFS", label: "Admiral Of Fleet Sir" },
    { value: "ADFL", label: "Admiral Of The Fleet" },
    { value: "ADMS", label: "Admiral Sir" },
    { value: "ADVO", label: "Advocate" },
    { value: "ACMS", label: "Air Chf Marshal Sir" },
    { value: "AIRC", label: "Air Chief Marshal" },
    { value: "ACDR", label: "Air Commodore" },
    { value: "AIRM", label: "Air Marshal" },
    { value: "AIMS", label: "Air Marshal Sir" },
    { value: "AIRV", label: "Air Vice Marshal" },
    { value: "ACFM", label: "Aircraftsman" },
    { value: "ACFW", label: "Aircraftswoman" },
    { value: "ASHF", label: "Al Sharif" },
    { value: "ALDE", label: "Alderman" },
    { value: "ALJI", label: "Alhaji" },
    { value: "AMBA", label: "Ambassador" },
    { value: "ARCH", label: "Archbishop" },
    { value: "ABOF", label: "Archbishop Of" },
    { value: "ARBS", label: "Archbishop Sir" },
    { value: "ARCD", label: "Archdeacon" },
    { value: "ASST", label: "Assistant" },
    { value: "ASSC", label: "Asst. Commissioner" },
    { value: "BAR", label: "Baron" },
    { value: "BARS", label: "Baroness" },
    { value: "BRNT", label: "Baronet" },
    { value: "BTSS", label: "Baronetess" },
    { value: "BSM", label: "Battery Sgnt Major" },
    { value: "BISH", label: "Bishop" },
    { value: "BSOF", label: "Bishop Of" },
    { value: "BOMB", label: "Bombadier" },
    { value: "BRIG", label: "Brigadier" },
    { value: "BGEN", label: "Brigadier General" },
    { value: "BRO", label: "Brother" },
    { value: "BURS", label: "Bursar" },
    { value: "BARC", label: "Barclays Bank" },
    { value: "CDT", label: "Cadet" },
    { value: "CNN", label: "Canon" },
    { value: "CPQM", label: "Capt Quartermaster" },
    { value: "CAPT", label: "Captain" },
    { value: "CSIR", label: "Captain Sir" },
    { value: "CDNL", label: "Cardinal" },
    { value: "CASH", label: "Cashier" },
    { value: "CHAM", label: "Chairman" },
    { value: "CEO", label: "Chairman Exec Of" },
    { value: "CHRP", label: "Chairperson" },
    { value: "CHAW", label: "Chairwoman" },
    { value: "CHAN", label: "Chancellor" },
    { value: "CHAP", label: "Chaplain" },
    { value: "CHF", label: "Chief" },
    { value: "CCOM", label: "Chief Commandant" },
    { value: "CCON", label: "Chief Constable" },
    { value: "CENG", label: "Chief Engineer" },
    { value: "CINS", label: "Chief Inspector" },
    { value: "COSF", label: "Chief Of Staff" },
    { value: "COFF", label: "Chief Officer" },
    { value: "CPOF", label: "Chief Petty Officer" },
    { value: "CSUP", label: "Chief Superintendent" },
    { value: "CFTE", label: "Chief Technician" },
    { value: "CWRN", label: "Chief Wren" },
    { value: "CHWR", label: "Churchwarden" },
    { value: "COL", label: "Colonel" },
    { value: "CCPL", label: "Colour Corporal" },
    { value: "CSGT", label: "Colour Sergeant" },
    { value: "CMFX", label: "Com Off" },
    { value: "COMM", label: "Commandant" },
    { value: "CMDR", label: "Commander" },
    { value: "CMDC", label: "Commander In Chief" },
    { value: "COMR", label: "Commissioner" },
    { value: "CDRE", label: "Commodore" },
    { value: "CSM", label: "Company Sgt Major" },
    { value: "CMTE", label: "Comte" },
    { value: "CONS", label: "Constable" },
    { value: "CONT", label: "Controller" },
    { value: "CPL", label: "Corporal" },
    { value: "CLLR", label: "Councillor" },
    { value: "COUN", label: "Count" },
    { value: "COOF", label: "Count Of" },
    { value: "COSS", label: "Countess" },
    { value: "CSOF", label: "Countess Of" },
    { value: "CRNA", label: "Crna" },
    { value: "DAME", label: "Dame" },
    { value: "DATN", label: "Datin" },
    { value: "DATO", label: "Dato" },
    { value: "DATK", label: "Datuk" },
    { value: "DCSS", label: "Deaconess" },
    { value: "D/M", label: "Dean Adm" },
    { value: "DNOF", label: "Dean Of" },
    { value: "DASC", label: "Dep.ass.commissioner" },
    { value: "DCCN", label: "Dep.chief Constable" },
    { value: "DCIN", label: "Dep.chief Inspector" },
    { value: "DPM", label: "Dep.provost Marshal" },
    { value: "DPY", label: "Deputy" },
    { value: "DCSP", label: "Deputy Chief Super." },
    { value: "DCOM", label: "Deputy Commissioner" },
    { value: "DL", label: "Deputy Lieutenant" },
    { value: "DETC", label: "Detective Constable" },
    { value: "DETI", label: "Detective Inspector" },
    { value: "DETS", label: "Detective Sergeant" },
    { value: "DETP", label: "Detective Superint." },
    { value: "DR", label: "Doctor" },
    { value: "DONA", label: "Dona" },
    { value: "DCOU", label: "Dowager Countess" },
    { value: "DWOF", label: "Dowager Countess Of" },
    { value: "DLAD", label: "Dowager Lady" },
    { value: "DOLA", label: "Dowager Lady Of" },
    { value: "DVR", label: "Driver" },
    { value: "DUCH", label: "Duchess" },
    { value: "DHOF", label: "Duchess Of" },
    { value: "DUKE", label: "Duke" },
    { value: "DUOF", label: "Duke Of" },
    { value: "ELAT", label: "E/late" },
    { value: "EARL", label: "Earl" },
    { value: "EAOF", label: "Earl Of" },
    { value: "ELDR", label: "Elder" },
    { value: "ENGR", label: "Engineer" },
    { value: "ENS", label: "Ensign" },
    { value: "EURG", label: "Eur Ing" },
    { value: "EXEC", label: "Executor" },
    { value: "FR", label: "Father" },
    { value: "FMAR", label: "Field Marshal" },
    { value: "FMAL", label: "Field Marshal Lord" },
    { value: "1ST", label: "First" },
    { value: "FLT", label: "First Lieutenant" },
    { value: "FOFR", label: "First Officer" },
    { value: "FLSG", label: "Flag Sergeant" },
    { value: "FCWR", label: "Fleet Chief Wren" },
    { value: "FCPO", label: "Fleet Chief Petty" },
    { value: "FOF", label: "Fleet Officer" },
    { value: "FRS", label: "Flying Officer" },
    { value: "FRSA", label: "Flying Officer Sir" },
    { value: "FMVS", label: "Fleet Master Warden" },
    { value: "FML", label: "Fleet Master Lord" },
    { value: "FS", label: "Flight Sergeant" },
    { value: "FLTO", label: "Flying Officer" },
    { value: "FR", label: "Father" },
    { value: "GEN", label: "General" },
    { value: "GGRT", label: "Governor General" },
    { value: "GGSS", label: "Governor General Sir" },
    { value: "GCV", label: "Governor Captain" },
    { value: "GOF", label: "Governor Of" },
    { value: "GPOT", label: "Governor General Lady" },
    { value: "GPSM", label: "Gunnery P.O. S.m." },
    { value: "GUND", label: "Gunner" },
    { value: "HON", label: "Honorary" },
    { value: "HONL", label: "Honorary Lady" },
    { value: "HONR", label: "Honorary Lord" },
    { value: "HR", label: "His Royal" },
    { value: "HSM", label: "Housemaster" },
    { value: "HEAD", label: "Head" },
    { value: "HEIR", label: "Heir" },
    { value: "INS", label: "Inspector" },
    { value: "IOMA", label: "Inspector Of" },
    { value: "INGR", label: "Ing" },
    { value: "INGI", label: "Ingineer" },
    { value: "INSI", label: "Inspector" },
    { value: "INSP", label: "Inspector" },
    { value: "JNR", label: "Junior" },
    { value: "JUST", label: "Justice" },
    { value: "LADY", label: "Lady" },
    { value: "LAOF", label: "Lady Of" },
    { value: "LDMS", label: "Lady Miss" },
    { value: "LDON", label: "Lady Consort" },
    { value: "LORD", label: "Lord" },
    { value: "LSOF", label: "Lord Of" },
    { value: "LT", label: "Lieutenant" },
    { value: "LTG", label: "Lieutenant General" },
    { value: "LTCO", label: "Lieutenant Colonel" },
    { value: "LTNC", label: "Lieutenant Com." },
    { value: "LTKM", label: "Lieutenant Comm." },
    { value: "LTKG", label: "Lieutenant Gov" },
    { value: "LTGV", label: "Lieutenant Gov." },
    { value: "LTMA", label: "Lieutenant Major" },
    { value: "LTMS", label: "Lieutenant Sir" },
    { value: "MRS", label: "Madam" },
    { value: "MALC", label: "Malko" },
    { value: "MARQ", label: "Marquess" },
    { value: "MQOF", label: "Marquess Of" },
    { value: "MLDY", label: "Marquis Lady" },
    { value: "MAJ", label: "Major" },
    { value: "MAJC", label: "Major Chaplain" },
    { value: "MAJO", label: "Major General" },
    { value: "MAJS", label: "Major Sir" },
    { value: "MANT", label: "Marchioness" },
    { value: "MAR", label: "Marshal" },
    { value: "MARS", label: "Marshal Sir" },
    { value: "MDAM", label: "Maddam" },
    { value: "MARQ", label: "Marquis" },
    { value: "MSTR", label: "Master" },
    { value: "MAJO", label: "Major" },
    { value: "MAYR", label: "Mayor" },
    { value: "MDMS", label: "Mdms" },
    { value: "MRSH", label: "Marsh" },
    { value: "MSUP", label: "Major Super" },
    { value: "MRSM", label: "Mrs Major" },
    { value: "NADH", label: "Nadhim" },
    { value: "NGVL", label: "Noble Gov Lady" },
    { value: "OBSI", label: "Obsir" },
    { value: "OFFI", label: "Officer" },
    { value: "PAR", label: "Paramount" },
    { value: "PAT", label: "Patriarch" },
    { value: "PATY", label: "Patrician" },
    { value: "PAD", label: "Padre" },
    { value: "PGNR", label: "Princess" },
    { value: "PFAM", label: "Priest Father" },
    { value: "PRLO", label: "Priest Lord" },
    { value: "PRSN", label: "Priest Sir" },
    { value: "PRSS", label: "Princess" },
    { value: "PRES", label: "President" },
    { value: "PRPL", label: "Prlnce/Prince" },
    { value: "PPWR", label: "Princess Consort" },
    { value: "PRSC", label: "Princess Consort" },
    { value: "PVP", label: "Provost" },
    { value: "PRWR", label: "Prince Consort" },
    { value: "RATY", label: "Ratna" },
    { value: "RNCH", label: "Rancher" },
    { value: "RBMN", label: "Rifleman" },
    { value: "REV", label: "Reverend" },
    { value: "REVD", label: "Rev Deacon" },
    { value: "RCOU", label: "Rector" },
    { value: "RDR", label: "Rector Designate" },
    { value: "REDA", label: "Rear Admiral" },
    { value: "RTAR", label: "Ret. Archbishop" },
    { value: "RTBP", label: "Ret. Bishop" },
    { value: "RTEC", label: "Ret. Canon" },
    { value: "RTCL", label: "Ret. Chaplain" },
    { value: "RTC", label: "Ret. Col" },
    { value: "REVD", label: "Reverend" },
    { value: "RCLL", label: "Ret. Collonel" },
    { value: "RDCL", label: "Ret. Dean" },
    { value: "RCF", label: "Ret. Fd Marshal" },
    { value: "RHSS", label: "Ret. High Sherff" },
    { value: "RGEN", label: "Ret. General" },
    { value: "RGRL", label: "Ret. Lieut General" },
    { value: "RMDL", label: "Ret. Major" },
    { value: "RNLC", label: "Ret. Mgn. Chancellor" },
    { value: "RNNL", label: "Ret. No New Titles" },
    { value: "RPVD", label: "Ret. Provost" },
    { value: "RVSR", label: "Ret. Vicar" },
    { value: "RSBR", label: "Ret. Sergeant" },
    { value: "RTSG", label: "Ret. Sir General" },
    { value: "RSHT", label: "Ret. Superintendent" },
    { value: "RTSP", label: "Ret. Supt" },
    { value: "RTSG", label: "Ret. Sub Sheriff" },
    { value: "RSNT", label: "Ret. Sergeant" },
    { value: "RVM", label: "Ret. Vice Admiral" },
    { value: "RTCO", label: "Rt Hon Viscountess" },
    { value: "RTCN", label: "Rt Hon Lady" },
    { value: "RTOF", label: "Rt Hon Lord" },
    { value: "RTHV", label: "Rt Hon Lord" },
    { value: "SPSH", label: "Special" },
    { value: "STFG", label: "Staff Grmt" },
    { value: "SGT", label: "Sergeant" },
    { value: "SGML", label: "Sergeant Major" },
    { value: "SJR", label: "Sir" },
    { value: "SLRD", label: "Sir Lord" },
    { value: "SRGA", label: "Sir General" },
    { value: "SPRS", label: "Sir President" },
    { value: "SINS", label: "Sir Inspector" },
    { value: "SKMR", label: "Sir King" },
    { value: "SMAR", label: "Sir Marshal" },
    { value: "SNMA", label: "Sir Naval Marshal" },
    { value: "SPRT", label: "Sir Provost" },
    { value: "STML", label: "Sir Ten Master" },
    { value: "SSMI", label: "Sir Superintendent" },
    { value: "SSMD", label: "Sir Sub Master" },
    { value: "SPMV", label: "Supreme Admiral" },
    { value: "TLMJ", label: "The Lord" },
    { value: "TGRD", label: "The Guard" },
    { value: "TLS", label: "The Lord" },
    { value: "TSHE", label: "The Sheikha" },
    { value: "UNCL", label: "Uncle" },
    { value: "VP", label: "Vice President" },
    { value: "VCOA", label: "Viscount Admiral" },
    { value: "VCAR", label: "Viscount" },
    { value: "VISR", label: "Viscountess" },
    { value: "VRDT", label: "Viscountess Ret." },
    { value: "VTMR", label: "Vicar General" },
    { value: "VTSD", label: "Vicar" },
    { value: "VLCK", label: "Vice Marshal" },
    { value: "WMD", label: "Warden Major" },
    { value: "WDML", label: "Warden Lord" },
    { value: "WPL", label: "Warrior Princess" },
    { value: "WPS", label: "Wife Of Prince" },
    { value: "WPSR", label: "Wife Of Sir" },
    { value: "WLAD", label: "Wife Of Lord" },
    { value: "WSHT", label: "Wife Of Sherff" },
    { value: "WVDM", label: "Wife Of Viscount" },
    { value: "WM", label: "Wife Marshal" },
    { value: "WGN", label: "Wife Governor" },
    { value: "WSC", label: "Wife Secretary" },
    { value: "YESS", label: "Yes" },
    { value: "YSSL", label: "Your Lordship" },
    { value: "YSHR", label: "Your Highness" },
    { value: "YSGN", label: "Your Grace" },
    { value: "YSHR", label: "Your Majesty" },
]