import React, { useState, useRef, useEffect } from "react";
import '../style/common.css';
import PersonalDetails from "./personal-details";
import EmploymentStatus from "./employment-details";
import EmiDetails from "./emi-details.js";
import ExpensesDetails from "./expenses-details.js";
import { StepProgressBar } from "../component/progressbar/index.tsx";
import { useNavigate, useParams } from "react-router-dom";

function MortgageForm() {
    const navigate = useNavigate();
    const childRef = useRef();
    const { step } = useParams();
    const [currentPage, setCurrentPage] = useState(0);

    // Use function declaration (hoisted)
    function onClickNextPage() {
        childRef.current.childFunction();
        const nextPage = currentPage + 1;
        if (nextPage < formStepsArray.length) {
            setCurrentPage(nextPage);
            navigate(`/mortgage_form/${nextPage}`);
            window.scrollTo(0, 0);
        }
    }

    const step1Content = <PersonalDetails onNextPage={onClickNextPage} />;
    const step2Content = <EmploymentStatus onNextPage={onClickNextPage} />;
    const step3Content = <ExpensesDetails onNextPage={onClickNextPage} />;
    const step4Content = <EmiDetails onNextPage={onClickNextPage} />;

    const formStepsArray = [
        {
            label: 'Step 1',
            subtitle: '',
            name: 'step 1',
            content: step1Content
        },
        {
            label: 'Step 2',
            subtitle: '',
            name: 'step 2',
            content: step2Content
        },
        {
            label: 'Step 3',
            subtitle: '',
            name: 'step 3',
            content: step3Content
        },
        {
            label: 'Step 4',
            subtitle: '',
            name: 'step 4',
            content: step4Content
        }
    ];

    useEffect(() => {
        const stepIndex = Number(step);
        if (stepIndex >= 0 && stepIndex < formStepsArray.length) {
            console.log("Current step:", stepIndex);
            setCurrentPage(stepIndex);
        } else {
            // Redirect to the last valid step if the step is invalid
            navigate(`/mortgage_form/${currentPage}`);
        }
    }, [step, navigate, formStepsArray.length]);

    function onFormSubmit() {
        // handle form submit logic here
    }

    function onclickStep(index) {
        if (index < formStepsArray.length) {
            setCurrentPage(index);
            navigate(`/mortgage_form/${index}`);
            window.scrollTo(0, 0);
        }
    }

    return (
        <>
            <div className="main_container">
                <StepProgressBar
                    ref={childRef}
                    startingStep={currentPage}
                    onSubmit={onFormSubmit}
                    steps={formStepsArray}
                    onStepClicked={onclickStep}
                />
            </div>
        </>
    );
}

export default MortgageForm;
