export function calculateYearsDurations(current_durations, previous_duration) {
    if (current_durations.days === "") {
        return null;
    }
    let totalDays = parseInt(current_durations.days.value, 10);
    let totalMonths = parseInt(current_durations.months.value, 10);
    let totalYears = parseInt(current_durations.years.value, 10);

    if (previous_duration.length > 0 && previous_duration[0].duration.days !== "") {
        previous_duration.forEach(duration => {
            totalDays += parseInt(duration.duration.days.value, 10);
            totalMonths += parseInt(duration.duration.months.value, 10);
            totalYears += parseInt(duration.duration.years.value, 10);
        });
    }

    if (totalDays >= 30) {
        totalMonths += Math.floor(totalDays / 30);
        totalDays = totalDays % 30;
    }

    if (totalMonths >= 12) {
        totalYears += Math.floor(totalMonths / 12);
        totalMonths = totalMonths % 12;
    }

    return {
        years: totalYears,
        months: totalMonths,
        days: totalDays
    };
}