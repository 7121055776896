import React from "react";
import '../style/header.css';

function Header() {
    return (
        <>
            <header className="headerStyle">
            {/* <ul>
                <li>Home</li>
                <li>About Us</li>
                <li>Contact Us</li>
            </ul>  */}
            </header>
        </>
    )
}

export default Header;