import React, { useState } from "react";
import { ButtonComponent, DatePickerComponent, InputField, PriceInputBox, SelectBox } from "../component/Common";
import { insuranceType, yesNoArray } from "../constants/form_constatnts";
import '../style/expenses.css';
const ExpensesDetails = ({ onNextPage }) => {
    const [expensesDetails, setexpensesDetails] = useState({
        electricityBill: '',
        gasBill: '',
        waterBill: '',
        mobileBill: '',
        telephoneBill: '',
        broadBandBill: '',
        skyTvBill: '',
        councilTaxBill: '',
        TvLicenceBill: '',
        foodBill: '',
        travelCostsBill: '',
        otherFixedExpenses: '',
        insurenceStatus: '',
        insurenceDetails: [
            {
                insuranceType: '',
                cost: '',
                renewalDate: '',
                providerName: '',
            }
        ]
    })
    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setexpensesDetails((prevData) => ({ ...prevData, [id]: value }));
    };
    const handleSelectChange = (value, name) => {
        setexpensesDetails((prevData) => ({ ...prevData, [name]: value }));
    };
    const handleDateChange = (date, name) => {
        setexpensesDetails((prevData) => ({ ...prevData, [name]: date }));
    };

    const handleFormSubmission = () => {
        onNextPage()
    }

    const addRemoveButtons = () => {
        return (
            <div className="input-field-row">
                {expensesDetails.insurenceDetails.length > 1 ? (
                    <button className="removeDivButton" onClick={() => removeInsurance()}>Remove Insurance</button>
                ) : (<div className="removeDivButton"></div>)}
                <button className="addDivButton" onClick={() => addInsurance()}>Add another Insurance</button>
            </div>
        )
    }

    const removeInsurance = () => {
        var nextInsurance = [...expensesDetails.insurenceDetails];
        if (nextInsurance.length > 1) {
            nextInsurance = nextInsurance.slice(0, nextInsurance.length - 1);
            setexpensesDetails({ ...expensesDetails, insurenceDetails: nextInsurance });
        }
    }
    const addInsurance = () => {
        setexpensesDetails((prevData) => ({
            ...prevData,
            insurenceDetails: [
                ...prevData.insurenceDetails,
                {
                    insuranceType: '',
                    cost: '',
                    renewalDate: '',
                    providerName: '',
                }
            ]
        }));
    }

    return (
        <>
            <div className="personal-details-form">
                <div className="form_name ">Expenses Details</div>
                <PriceInputBox
                    label="Electricity Bill:"
                    htmlFor="electricityBill"
                    value={expensesDetails.electricityBill}
                    onChange={handleInputChange}
                />
                <PriceInputBox
                    label="Gas Bill:"
                    htmlFor="gasBill"
                    value={expensesDetails.gasBill}
                    onChange={handleInputChange}
                />
                <PriceInputBox
                    label="Water Bill:"
                    htmlFor="waterBill"
                    value={expensesDetails.waterBill}
                    onChange={handleInputChange}
                />
                <PriceInputBox
                    label="Mobile Bill:"
                    htmlFor="mobileBill"
                    value={expensesDetails.mobileBill}
                    onChange={handleInputChange}
                />
                <PriceInputBox
                    label="Telephone Bill:"
                    htmlFor="telephoneBill"
                    value={expensesDetails.telephoneBill}
                    onChange={handleInputChange}
                />
                <PriceInputBox
                    label="Broadband Bill:"
                    htmlFor="broadBandBill"
                    value={expensesDetails.broadBandBill}
                    onChange={handleInputChange}
                />
                <PriceInputBox
                    label="Sky TV Bill:"
                    htmlFor="skyTvBill"
                    value={expensesDetails.skyTvBill}
                    onChange={handleInputChange}
                />
                <PriceInputBox
                    label="Council Tax Bill:"
                    htmlFor="councilTaxBill"
                    value={expensesDetails.councilTaxBill}
                    onChange={handleInputChange}
                />
                <PriceInputBox
                    label="TV Licence Bill:"
                    htmlFor="TvLicenceBill"
                    value={expensesDetails.TvLicenceBill}
                    onChange={handleInputChange}
                />
                <PriceInputBox
                    label="Food Bill:"
                    htmlFor="foodBill"
                    value={expensesDetails.foodBill}
                    onChange={handleInputChange}
                />
                <PriceInputBox
                    label="Travel Costs Bill:"
                    htmlFor="travelCostsBill"
                    value={expensesDetails.travelCostsBill}
                    onChange={handleInputChange}
                />
                <PriceInputBox
                    label="Other: Fixed Expenses"
                    htmlFor="otherFixedExpenses"
                    value={expensesDetails.otherFixedExpenses}
                    onChange={handleInputChange}
                />
                <hr />
                <p className="formHeading">Insurance Details</p>
                <SelectBox
                    label="Do you currently have any insurance coverage?"
                    htmlFor="insurenceStatus"
                    options={yesNoArray}
                    value={expensesDetails.insurenceStatus}
                    placeholder="Select an option"
                    onChange={(value) => handleSelectChange(value, 'insurenceStatus')}
                />
                {expensesDetails.insurenceStatus && expensesDetails.insurenceStatus.value === 'Yes' && (
                    <>
                        {expensesDetails.insurenceDetails.map((insurance, index) => {
                            return <div key={index}>
                                {index > 0 && (<hr />)}
                                <p className="formHeading">{`${index + 1}. Insurance Details`}</p>
                                <SelectBox
                                    label="Select Insurance Type:"
                                    htmlFor="insuranceType"
                                    options={insuranceType}
                                    value={insurance.insuranceType}
                                    placeholder="Select Insurance Type"
                                    onChange={(value) => handleSelectChange(value, 'insuranceType')}
                                />
                                <PriceInputBox
                                    label="Amount to be Paid:"
                                    htmlFor="cost"
                                    value={insurance.cost}
                                    onChange={handleInputChange}
                                />
                                <DatePickerComponent
                                    label="Date of Renewal:"
                                    htmlFor="renewal"
                                    value={insurance.renewalDate}
                                    onChange={(date) => handleDateChange(date, 'renewalDate')}
                                />
                                <InputField
                                    label="Enter Provider's Name:"
                                    htmlFor="providerName"
                                    placeholder="Enter your insurance provider's name"
                                    value={insurance.providerName}
                                    onChange={handleInputChange}
                                />
                            </div>
                        })}
                        {addRemoveButtons()}
                    </>
                )}
                <ButtonComponent label="Submit" name='Proceed' onClick={handleFormSubmission} />
            </div>
        </>
    )

}

export default ExpensesDetails;