import React, { useState } from "react";
import { ButtonComponent, DatePickerComponent, InputField, PriceInputBox, SelectBox } from "../component/Common";
import { businessRoles, employmentStatus, employmentTypeList, occupationList, otherIncomeList, universalBenifitsList, yesNoArray } from "../constants/form_constatnts";
import '../style/employmentForm.css';

//employment form array 
const EmploymentStatus = ({ onNextPage }) => {
    const [emlpoymentData, setemlpoymentData] = useState({
        employmentStatus: '',
        retirementAge: '',
        //employment fields
        occupation: '',
        employmentStartDate: '',
        employmentType: '',
        annualSalary: '',
        monthlySalary: '',
        texRate: '',
        employerName: '',
        companyRegisteredAddress: '',
        previousEmployerName: '',
        previousJobStartDate: '',
        previousSalary: '',
        previousEmployersAddress: '',
        //common fields
        universalBenifitSource: '',
        NInumber: '',
        accountantFirm: '',
        actingAccountant: '',
        address: '',
        postCode: '',
        otherIncomeStatus: '',
        //business array
        business: [
            {
                businessRole: '',
                businessPercentage: '',
                selfBusinessName: '',
                selfEmploymentDuration: '',                
                latestAccountingYear: '',
                latestyearTotalIncome: '',
                latestYearsNetProfit: '',
                latestYearsSlary: '',
                latestYearsProfitFromLands: '',
                latestYearsDividends: '',
                previousAccountingYear: '',
                previousYearTotalIncome: '',
                previousYearsNetProfit: '',
                previousYearsSlary: '',
                previousYearsProfitFromLands: '',
                previousYearsDividends: '',
            }
        ]
    })

    // Handle change for select inputs
    const handleSelectChange = (value, name) => {
        setemlpoymentData((prevData) => ({ ...prevData, [name]: value }));
    };

    // Handle date change
    const handleDateChange = (date, name) => {
        setemlpoymentData((prevData) => ({ ...prevData, [name]: date }));
    };

    //handle price change
    const handlePriceChange = (e) => {
        const { id, value } = e.target;
        setemlpoymentData((prevData) => ({ ...prevData, [id]: value }));
    }

    //handle input change
    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setemlpoymentData((prevData) => ({ ...prevData, [id]: value }));
    };

    //handle busniess change(add more business...)
    const handleBusinessChange = (index, name, value) => {

        const newBusiness = [...emlpoymentData.business];
        newBusiness[index] = {
            ...newBusiness[index],
            [name]: value
        };
        setemlpoymentData({ ...emlpoymentData, business: newBusiness });
    };

    //handle business input field change
    const handleBusinessInputChange = (index, name, e) => {
        const { value } = e.target;
        const newBusiness = [...emlpoymentData.business];
        newBusiness[index] = {
            ...newBusiness[index],
            [name]: value
        };
        setemlpoymentData({ ...emlpoymentData, business: newBusiness });
    }

    //add more business function
    const addBusiness = () => {
        setemlpoymentData((prevData) => ({
            ...prevData,
            business: [
                ...prevData.business,
                {
                    businessRole: '',
                    businessPercentage: '',
                    selfBusinessName: '',
                    selfEmploymentDuration: '',
                    latestAccountingYear: '',
                    latestyearTotalIncome: '',
                    latestYearsNetProfit: '',
                    latestYearsSlary: '',
                    latestYearsProfitFromLands: '',
                    latestYearsDividends: '',
                    previousAccountingYear: '',
                    previousYearTotalIncome: '',
                    previousYearsNetProfit: '',
                    previousYearsSlary: '',
                    previousYearsProfitFromLands: '',
                    previousYearsDividends: '',
                }
            ]
        }));
    }

    //remove business function
    const removeBusiness = () => {
        var newBusiness = [...emlpoymentData.business];
        if (newBusiness.length > 1) {
            newBusiness = newBusiness.slice(0, newBusiness.length - 1);
            setemlpoymentData({ ...emlpoymentData, business: newBusiness });
        }
    }

    //employment form component
    const handleEmploymentForm = () => {
        return (
            <>
                <p className="formHeading">Employment Details</p>
                <SelectBox
                    label="Occupation:"
                    options={occupationList}
                    htmlFor="occupation"
                    value={emlpoymentData.occupation}
                    placeholder="Select your occupation"
                    onChange={(value) => handleSelectChange(value, 'occupation')}
                />
                <DatePickerComponent
                    label="Start date of Employment:"
                    htmlFor="job-start-date"
                    value={emlpoymentData.employmentStartDate}
                    onChange={(date) => handleDateChange(date, 'employmentStartDate')}
                />
                <SelectBox
                    label="Employment Type:"
                    htmlFor="employment-type"
                    placeholder="Select employment type"
                    options={employmentTypeList}
                    value={emlpoymentData.employmentType}
                    onChange={(value) => handleSelectChange(value, 'employmentType')}
                />
                <PriceInputBox
                    label="Basic Annual salary:"
                    htmlFor="annualSalary"
                    value={emlpoymentData.annualSalary}
                    onChange={handlePriceChange}
                />
                <PriceInputBox
                    label="Net Monthly Pay:"
                    htmlFor="monthlySalary"
                    value={emlpoymentData.monthlySalary}
                    onChange={handlePriceChange}
                />
                <InputField
                    label="Tax Rate:"
                    htmlFor="texRate"
                    value={emlpoymentData.texRate}
                    onChange={handleInputChange}
                    placeholder="Enter your tex rate"
                />
                <InputField
                    label="Employer Name:"
                    htmlFor="employerName"
                    value={emlpoymentData.employerName}
                    onChange={handleInputChange}
                    placeholder="Enter your employer name"
                />
                <InputField
                    label="Enter Company's Registered Address:"
                    htmlFor="companyRegisteredAddress"
                    value={emlpoymentData.companyRegisteredAddress}
                    onChange={handleInputChange}
                    placeholder="Enter company's registered address"
                />
                <InputField
                    label="Previous Employer Name:"
                    htmlFor="previousEmployerName"
                    value={emlpoymentData.previousEmployerName}
                    onChange={handleInputChange}
                    placeholder="Enter previous employer name"
                />
                <DatePickerComponent
                    label="Start date of Employment:"
                    htmlFor="previousJobStartDate"
                    value={emlpoymentData.previousJobStartDate}
                    onChange={(date) => handleDateChange(date, 'previousJobStartDate')}
                />
                <PriceInputBox
                    label="Previous Salary:"
                    htmlFor="previousSalary"
                    value={emlpoymentData.previousSalary}
                    onChange={handlePriceChange}
                />
                <InputField
                    label="Previous Employer's Address:"
                    htmlFor="previousEmployersAddress"
                    value={emlpoymentData.previousEmployersAddress}
                    onChange={handleInputChange}
                    placeholder="Enter previous employer's address"
                />
            </>
        )
    }

    //self employment form component
    const handleSelfEmploymentForm = (business, index) => {
        return (
            <>                
                <p className="formHeading">{`${index + 1}. Business Details`}</p>
                <SelectBox
                    label="Select your Business Role:"
                    htmlFor={`businessRole${index}`}
                    placeholder="Select your business role"
                    value={business.businessRole}
                    options={businessRoles}
                    onChange={(value) => handleBusinessChange(index, `businessRole`, value)}
                />
                {business.businessRole && (business.businessRole.value === 'Partner' || business.businessRole.value === 'Shareholder') && (
                    <>
                        <InputField
                            label="Enter Percentage of business you own:"
                            htmlFor={`businessPercentage${index}`}
                            value={business.businessPercentage}
                            onChange={(value) => handleBusinessInputChange(index, "businessPercentage", value)}
                            placeholder="Enter percentage of business you own"
                        />
                    </>
                )}
                <InputField
                    label="Enter Name of Your Business:"
                    htmlFor={`selfBusinessName${index}`}
                    value={business.selfBusinessName}
                    onChange={(value) => handleBusinessInputChange(index, "selfBusinessName", value)}
                    placeholder="Enter your business name"
                />
                <DatePickerComponent
                    label="What is the duration of your current Self-Employment?"
                    htmlFor="selfEmploymentDuration"
                    value={business.selfEmploymentDuration}
                    onChange={(date) => handleBusinessChange(index, 'selfEmploymentDuration', date)}
                />
                <p className="formHeading">Latest Accounting Year Details</p>
                <DatePickerComponent
                    label="Latest Accounting Year:"
                    htmlFor="latestAccountingYear"
                    value={business.latestAccountingYear}
                    onChange={(date) => handleBusinessChange(index, 'latestAccountingYear', date)}                    
                />
                <PriceInputBox
                    label="Latest years's Total Income:"
                    htmlFor="latestyearTotalIncome"
                    value={business.latestyearTotalIncome}
                    onChange={(value) => handleBusinessInputChange(index, "latestyearTotalIncome", value)}
                />
                <PriceInputBox
                    label="Latest year's Net Profit:"
                    htmlFor="latestYearsNetProfit"
                    value={business.latestYearsNetProfit}
                    onChange={(value) => handleBusinessInputChange(index, "latestYearsNetProfit", value)}
                />
                <PriceInputBox
                    label="Latest year's Salary:"
                    htmlFor="latestYearsSlary"
                    value={business.latestYearsSlary}
                    onChange={(value) => handleBusinessInputChange(index, "latestYearsSlary", value)}
                />
                <PriceInputBox
                    label="Latest year's Profit From Lands:"
                    htmlFor="latestYearsProfitFromLands"
                    value={business.latestYearsProfitFromLands}
                    onChange={(value) => handleBusinessInputChange(index, "latestYearsProfitFromLands", value)}
                />
                <PriceInputBox
                    label="Latest year's Dividends:"
                    htmlFor="netProfit"
                    value={business.netProfit}
                    onChange={(value) => handleBusinessInputChange(index, "netProfit", value)}
                />
                <p className="formHeading">Previous Accounting Year Details</p>
                <DatePickerComponent
                    label="Previous Accounting Year:"
                    htmlFor="PreviousAccountingYear"
                    value={business.PreviousAccountingYear}
                    onChange={(date) => handleBusinessChange(index, 'PreviousAccountingYear', date)}    
                />
                <PriceInputBox
                    label="Previous years's Total Income:"
                    htmlFor="previousYearTotalIncome"
                    value={business.previousYearTotalIncome}
                    onChange={(value) => handleBusinessInputChange(index, "previousYearTotalIncome", value)}
                />
                <PriceInputBox
                    label="Previous year's Net Profit:"
                    htmlFor="previousYearsNetProfit:"
                    value={business.previousYearsNetProfit}
                    onChange={(value) => handleBusinessInputChange(index, "previousYearsNetProfit", value)}
                />
                <PriceInputBox
                    label="Previous year's Salary:"
                    htmlFor="previousYearsSlary"
                    value={business.previousYearsSlary}
                    onChange={(value) => handleBusinessInputChange(index, "previousYearsSlary", value)}
                />
                <PriceInputBox
                    label="Previous year's Profit From Lands:"
                    htmlFor="previousYearsProfitFromLands"
                    value={business.previousYearsProfitFromLands}
                    onChange={(value) => handleBusinessInputChange(index, "previousYearsProfitFromLands", value)}
                />
                <PriceInputBox
                    label="Previous year's Dividends:"
                    htmlFor="previousYearsDividends"
                    value={business.previousYearsDividends}
                    onChange={(value) => handleBusinessInputChange(index, "previousYearsDividends", value)}
                />
            </>
        )
    }

    //universal creadits form component
    const handleUniversalCreditForm = () => {
        return (
            <>
                <p className="formHeading">Universal Credits Details</p>
                <SelectBox
                    label="Select Source of  Universal Credits:"
                    htmlFor="universalBenifitSource"
                    options={universalBenifitsList}
                    placeholder="Select other income source"
                    value={emlpoymentData.universalCreditSource}
                    onChange={(value) => handleSelectChange(value, 'universalBenifitSource')}
                />
                <PriceInputBox
                    label="Amount Recieved Per Month:"
                    htmlFor="unversalCreditAmount"
                    value={emlpoymentData.unversalCreditAmount}
                    onChange={handlePriceChange}
                />
                <InputField
                    label="Enter Bank Name:"
                    htmlFor="bankName"
                    value={emlpoymentData.bankName}
                    onChange={handleInputChange}
                    placeholder="Enter your bank name"
                />
                {emlpoymentData.otherIncomeSource && emlpoymentData.otherIncomeSource.value !== '' && (
                    <>

                    </>
                )}
            </>
        )
    }

    //any other income details form component
    const handleOtherIncomeForm = () => {
        return (
            <>
                <hr />
                <p className="formHeading">Any other Income Source Details</p>
                <SelectBox
                    label="Do you have any other income ?"
                    htmlFor="otherIncomeStatus"
                    placeholder="Select an option"
                    options={yesNoArray}
                    value={emlpoymentData.otherIincomeStatus}
                    onChange={(value) => handleSelectChange(value, 'otherIncomeStatus')}
                />
                {emlpoymentData.otherIncomeStatus && emlpoymentData.otherIncomeStatus.value === 'Yes' && (
                    <>
                        <SelectBox
                            label="Select Other Income Source"
                            htmlFor="otherIncomeSource"
                            options={otherIncomeList}
                            placeholder="Select other income source"
                            value={emlpoymentData.otherIncomeSource}
                            onChange={(value) => handleSelectChange(value, 'otherIncomeSource')}
                        />
                        <PriceInputBox
                            label="Amount:"
                            htmlFor="otherIncomeAmount"
                            value={emlpoymentData.otherIncomeAmount}
                            onChange={handlePriceChange}
                        />
                    </>
                )}
            </>
        )
    }

    //accounting data form component
    const handleAccountingForm = () => {
        return (
            <>
                <hr />
                <p className="formHeading">Accounting Details</p>
                <InputField
                    label="National Insurance (NI) Number:"
                    htmlFor="NInumber"
                    value={emlpoymentData.NInumber}
                    onChange={handleInputChange}
                    placeholder="Enter your NI number"
                />
                <InputField
                    label="Name of Accountants Firm:"
                    htmlFor="accountantFirm"
                    value={emlpoymentData.accountantFirm}
                    onChange={handleInputChange}
                    placeholder="Enter name of your accountant firm"
                />
                <InputField
                    label="Name of Acting Accountant:"
                    htmlFor="actingAccountant"
                    value={emlpoymentData.actingAccountant}
                    onChange={handleInputChange}
                    placeholder="Enter name of your acting accountant"
                />
                <InputField
                    label="Address:"
                    htmlFor="address"
                    value={emlpoymentData.address}
                    onChange={handleInputChange}
                    placeholder="Enter address"
                />
                <InputField
                    label="Enter Post Code:"
                    htmlFor="postCode"
                    value={emlpoymentData.postCode}
                    onChange={handleInputChange}
                    placeholder="Enter your post code"
                />
            </>
        )
    }

    //add and remove button
    const addRemoveButtons = () => {
        return (
            <div className="input-field-row">
                {emlpoymentData.business.length > 1 ? (
                    <button className="removeDivButton" onClick={() => removeBusiness()}>Remove business</button>
                ) : (<div className="removeDivButton"></div>)}
                <button className="addDivButton" onClick={() => addBusiness()}>Add another business</button>
            </div>
        )
    }
    // Handle form submission
    const handleFormSubmission = () => {
        onNextPage()
    };

    //form return
    return (
        <>
            <div className="personal-details-form">
                <div className="form_name ">Employment Details</div>
                <SelectBox
                    isMulti={true}
                    label="Select Employement Status:"
                    options={employmentStatus}
                    htmlFor="employment-status"
                    name="employmentStatus"
                    value={emlpoymentData.employmentStatus}
                    placeholder='Select emloyement status'
                    onChange={(value) => handleSelectChange(value, 'employmentStatus')}
                />
                {emlpoymentData.employmentStatus && emlpoymentData.employmentStatus.value !== 'Neither' && (
                    <InputField
                        label="Retirement Age:"
                        htmlFor="retirementAge"
                        value={emlpoymentData.retirementAge}
                        onChange={handleInputChange}
                        placeholder="Enter your retirement age"
                    />
                )}
                {emlpoymentData.employmentStatus.value === 'Employed' && (
                    <>
                        <hr /> {handleEmploymentForm()}
                        <hr /> {handleUniversalCreditForm()} {handleAccountingForm()}
                    </>
                )}
                {emlpoymentData.employmentStatus.value === 'Self-Employed' && (
                    <> <hr />
                        <p className="formHeading">Self Employment Details</p>
                        {emlpoymentData.business && emlpoymentData.business.map((business, index) => (
                            <div key={index}>
                                {index != 0 && (<hr />)}
                                {handleSelfEmploymentForm(business, index)}
                            </div>
                        ))}
                        {addRemoveButtons()}
                        <hr /> {handleUniversalCreditForm()} {handleAccountingForm()}
                    </>
                )}
                {emlpoymentData.employmentStatus && emlpoymentData.employmentStatus.value === 'Both' && (
                    <>
                        <hr /> {handleEmploymentForm()}
                        <hr />
                        <p className="formHeading">Self Employment Details</p>
                        {emlpoymentData.business && emlpoymentData.business.map((business, index) => (
                            <div key={index}>
                                {index != 0 && (<hr />)}
                                {handleSelfEmploymentForm(business, index)}
                            </div>
                        ))}
                        {addRemoveButtons()}
                        <hr /> {handleUniversalCreditForm()} {handleAccountingForm()}
                    </>
                )}
                {emlpoymentData.employmentStatus && (handleOtherIncomeForm())}
                <ButtonComponent label="Submit" name='Proceed' onClick={handleFormSubmission} />
            </div>
        </>
    );
};

export default EmploymentStatus;