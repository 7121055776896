import React, { useState } from 'react';
import '../style/home.css';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { MdCurrencyPound } from "react-icons/md";

// Define the array of loan types
const loanTypes = [
  { value: 'first-time-buyer', label: 'First Time Buyer Mortgage' },
  { value: 'buy-to-let', label: 'Buy to Let Mortgage' },
  { value: 'remortgage', label: 'Remortgage' },
];

const applicantArry = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
]

function Home() {
  const [loanType, setLoanType] = useState('');
  const [applicantCount, setApplicantCount] = useState(0);
  const [loanAmount, setLoanAmount] = useState(''); // State for loan amount
  const navigate = useNavigate();

  const handleLoanTypeChange = (e) => {
    setLoanType(e);
  };

  const handleApplicantChange = (e) => {
    setApplicantCount(e);
  };

  const handleLoanAmountChange = (e) => {
    let input = e.target.value;
    input = input.replace(/\D/g, '');
    const formattedNumber = new Intl.NumberFormat('en-IN').format(input);
    setLoanAmount(formattedNumber);
  };

  const handleProceed = () => {
    if (loanType && applicantCount.value > 0 && loanAmount) {
      navigate('/mortgage_form/0', { state: { loanType, applicantCount, loanAmount } });
    } else {
      alert('Please select loan type, number of applicants, and enter the required loan amount');
    }
  };

  return (
    <div className="home_container">
      <main className="main-content">
        <h1>Mortgage Loan Application Form</h1>
        <div className='loan_form'>
          <div className='select_loan_type'>
            <div className='name_div'>
              <h2>Select Loan Type</h2>
            </div>
            <div className='data_div'>
              <Select value={loanType}
                onChange={handleLoanTypeChange}
                className='loanType'
                placeholder='Select Loan Type'
                options={loanTypes}
              />
            </div>
          </div>
          <div className='select_applicant'>
            <div className='name_div'>
              <h2>Number of Applicants</h2>
            </div>
            <div className='data_div'>
              <Select value={applicantCount}
                onChange={handleApplicantChange}
                className="applicant-count"
                placeholder="Select No. of Applicants"
                options={applicantArry}
              />
            </div>
          </div>
          <div className='select_loan_amount'>
            <div className='name_div'>
              <h2>Required Loan Amount</h2>
            </div>
            <div className="data_div" style={{ position: 'relative' }}>
              <span className="currency_icon" style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)' }}>
                <MdCurrencyPound style={{ color: 'gray' }} />
              </span>
              <input
                className="loan-amount"
                style={{ paddingLeft: '35px' }}
                placeholder="Enter required loan amount"
                htmlFor="requiredLoanAmount"
                value={loanAmount}
                onChange={handleLoanAmountChange}
              />
            </div>
          </div>
          <center><button type="button" className="proceed-btn" onClick={handleProceed}> Proceed </button></center>
        </div>
      </main>
    </div>
  );
}

export default Home;